import request from '@/utils/requset'
import qs from 'qs'
export const login = (data) => {
  return request({
    url: '/login',
    method: 'POST',
    data: qs.stringify(data)
  })
}

export const captchaImage = () => {
  return request({
    url: '/captcha/captchaImage',
    method: 'GET',
    responseType: 'arraybuffer',
    params: { type: 'math' }
  })
}
export const loginProfile = () => {
  return request({
    url: '/common/profile',
    method: 'GET'
  })
}

export const getMenus = () => {
  return request({
    url: '/common/getMenus',
    method: 'GET'
  })
}

export const resetPwd = (info) => {
  return request({
    url: '/system/user/profile/resetPwd',
    method: 'POST',
    data: qs.stringify(info)
  })
}

export const commonDownload = (fileName, isDelete = true) => {
  return request({
    url: '/common/download',
    method: 'GET',
    responseType: 'blob',
    params: {
      fileName,
      delete: isDelete
    }
  })
}

export const syncWxDepartment = () => {
  return request({
    url: '/task/syncWxDepartment',
    method: 'POST'
  })
}
