import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2e8592c8"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
import { computed } from 'vue';
import { isExternal as external } from '@/utils/validate';
export default {
  __name: 'index',
  props: {
    icon: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const isExternal = computed(() => external(props.icon));
    const styleExternalIcon = computed(() => ({
      mask: `url(${props.icon}) no-repeat 50% 50%`,
      '-webkit-mask': `url(${props.icon}) no-repeat 50% 50%`
    }));
    const iconName = computed(() => `#icon-${props.icon}`);
    return (_ctx, _cache) => {
      return isExternal.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(styleExternalIcon.value),
        class: _normalizeClass(["svg-external-icon svg-icon", __props.className])
      }, null, 6)) : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        class: _normalizeClass(["svg-icon", __props.className]),
        "aria-hidden": "true"
      }, [_createElementVNode("use", {
        "xlink:href": iconName.value
      }, null, 8, _hoisted_1)], 2));
    };
  }
};