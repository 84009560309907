import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3ed4c461"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-container"
};
const _hoisted_2 = {
  class: "fixed-header"
};
const _hoisted_3 = {
  class: "main-section"
};
import Sidebar from '@/views/layout/components/Sidebar';
import Navbar from '@/views/layout/components/Navbar';
import AppMain from '@/views/layout/components/AppMain';
import variables from '@/styles/variables.module.scss';
import TagsView from '@/components/TagsView';
import { debounce } from '@/utils';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import store from '@/store';
import { TRIGGER_SIDEBAR_OPENED_MUTATION } from '@/store/mutation-types';
import { useRoute } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const screenWidth = ref(window.innerWidth);
    const route = useRoute();
    const getWindowInfo = () => {
      screenWidth.value = window.innerWidth;
    };
    onMounted(() => {
      window.addEventListener('resize', debounce(getWindowInfo));
    });
    watch(() => screenWidth.value, value => {
      store.commit('app/' + TRIGGER_SIDEBAR_OPENED_MUTATION, value > 1440);
    }, {
      immediate: true
    });
    onUnmounted(() => {
      window.removeEventListener('resize', debounce(getWindowInfo));
    });
    return (_ctx, _cache) => {
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["layout-container", [_ctx.$store.getters.sidebarOpened ? 'openSidebar' : 'hideSidebar']])
      }, [_createVNode(_unref(Sidebar), {
        class: "sidebar-container",
        id: "guide-sidebar",
        style: _normalizeStyle({
          backgroundColor: _unref(variables).menuBg
        })
      }, null, 8, ["style"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(Navbar))]), _createElementVNode("div", _hoisted_3, [_unref(route).fullPath !== '/home' ? (_openBlock(), _createBlock(_unref(TagsView), {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(_component_el_scrollbar, {
        style: {
          "border-top-right-radius": "8px",
          "border-bottom-left-radius": "8px",
          "border-bottom-right-radius": "8px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_unref(AppMain))]),
        _: 1
      })])])], 2);
    };
  }
};