import store from '@/store'
const buttonData = new Map([
  ['file_template_import', '2012'],
  ['file_template_online', '2013'],
  ['file_template_offline', '2015'],
  ['file_template_delete', '2016'],
  ['push_task_addition', '2017'],
  ['push_task_push', '2018'],
  ['push_task_modify_scope', '2019'],
  ['employee_sign_rePush', '2020'],
  ['employee_sig_pigeonhole', '2021'],
  ['employee_sig_cancel_pigeonhole', '2022']
])
function checkPermission(el, binding) {
  const { value } = binding
  const points = store.getters.points
  if (value) {
    const id = buttonData.has(value[0]) ? buttonData.get(value[0]) : ''
    const hasPermission = points.some((point) => {
      return id === point
    })
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  } else {
    throw new Error('v-permission value same 1')
  }
}
export default {
  mounted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
}
