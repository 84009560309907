import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/logo.png';
import _imports_1 from '@/assets/small-logo.png';
const _withScopeId = n => (_pushScopeId("data-v-3cdd3b1b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "logo"
};
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "logo"
};
import SidebarMenu from '@/views/layout/components/Sidebar/SidebarMenu.vue';
export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
        class: _normalizeClass(["logo", {
          hideLogo: !_ctx.$store.getters.sidebarOpened
        }])
      }, [_ctx.$store.getters.sidebarOpened ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : (_openBlock(), _createElementBlock("img", _hoisted_2))], 2), _createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [_createVNode(SidebarMenu)]),
        _: 1
      })]);
    };
  }
};