import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/views/layout'
import AccountList from '@/router/modules/AccountList'
import AppPermissionManage from '@/router/modules/AppPermissionManage'
import FileTemplate from '@/router/modules/FileTemplate'
import PushTask from '@/router/modules/PushTask'
import EmployeeSignature from '@/router/modules/EmployeeSignature'
import UserManage from '@/router/modules/UserManage'
import RoleManage from '@/router/modules/RoleManage'
import store from '@/store'
import {
  SET_MENU_LIST_MUTATION,
  SET_PERMISSION_MUTATION
} from '@/store/mutation-types'

export const privateRoutes = [
  AppPermissionManage,
  AccountList,
  FileTemplate,
  PushTask,
  EmployeeSignature,
  RoleManage,
  UserManage
]
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home')
      },

      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401.vue')
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error-page/404.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
})
export function resetRouter() {
  if (store.getters.hasPermissionList) {
    const menus = store.getters.permission
    menus.forEach((menu) => {
      router.removeRoute(menu)
    })
    store.commit('permission/' + [SET_PERMISSION_MUTATION], [], { root: true })
    store.commit('permission/' + [SET_MENU_LIST_MUTATION], [], { root: true })
  }
}
export default router
