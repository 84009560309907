import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "change-password-container"
};
import MyDialog from '@/components/My-Dialog';
import { ref, watch } from 'vue';
import { resetPwd } from '@/api/sys';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import { checkPassword } from '@/utils';
export default {
  __name: 'change-password',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const changePasswordVisible = ref(true);
    watch(() => props.modelValue, value => {
      changePasswordVisible.value = value;
    }, {
      immediate: true
    });
    watch(changePasswordVisible, value => {
      emits('update:modelValue', changePasswordVisible.value);
    });
    const ruleFormRef = ref(null);
    const ruleForm = ref({
      oldPassword: '',
      newPassword: '',
      confirm_password: ''
    });
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (!checkPassword(value)) {
        callback(new Error('密码必须8-16位字符，包含大小写字母，数字和特殊字符中的三种及以上'));
      } else {
        if (!ruleFormRef.value) return;
        ruleFormRef.value.validateField('confirmPassword', () => null);
      }
      callback();
    };
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'));
      } else if (value !== ruleForm.value.newPassword) {
        callback(new Error('新密码和确认密码不一致'));
      } else {
        callback();
      }
    };
    const rules = ref({
      oldPassword: [{
        required: true,
        message: '请输入旧密码',
        trigger: 'change'
      }],
      newPassword: [{
        required: true,
        validator: validatePass,
        trigger: 'change'
      }],
      confirmPassword: [{
        required: true,
        validator: validateConfirmPass,
        trigger: 'change'
      }]
    });
    const store = useStore();
    const confirmLoading = ref(false);
    const handleSubmit = close => {
      ruleFormRef.value.validate(validate => {
        if (!validate) return;
        confirmLoading.value = true;
        const {
          oldPassword,
          newPassword
        } = ruleForm.value;
        resetPwd({
          oldPassword,
          newPassword
        }).then(res => {
          if (res.code === 0) {
            ElMessage.success('修改密码成功，请重新登录');
            close();
            store.dispatch('user/logout');
          } else {
            ElMessage.error('修改密码失败');
          }
        }).finally(() => {
          confirmLoading.value = false;
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(MyDialog), {
        title: "修改密码",
        modelValue: changePasswordVisible.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => changePasswordVisible.value = $event),
        "onOn:submit": handleSubmit,
        "confirm-loading": confirmLoading.value
      }, {
        content: _withCtx(() => [_createVNode(_component_el_form, {
          "label-position": "left",
          ref_key: "ruleFormRef",
          ref: ruleFormRef,
          model: ruleForm.value,
          rules: rules.value,
          "label-width": "142px",
          "status-icon": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "旧密码",
            prop: "oldPassword"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: ruleForm.value.oldPassword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ruleForm.value.oldPassword = $event),
              type: "password",
              "show-password": "",
              class: "w-400"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "新密码",
            prop: "newPassword"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: ruleForm.value.newPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ruleForm.value.newPassword = $event),
              type: "password",
              "show-password": "",
              class: "w-400"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "再次密码",
            prop: "confirmPassword"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: ruleForm.value.confirmPassword,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ruleForm.value.confirmPassword = $event),
              type: "password",
              "show-password": "",
              class: "w-400"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "confirm-loading"])]);
    };
  }
};