import Layout from '@/views/layout'
export default {
  path: '/sign-document',
  component: Layout,
  name: 'employeeSignatureStatus',
  redirect: '/employee-signature-status',
  meta: {
    title: '签字文件推送',
    icon: 'contract-sign'
  },
  children: [
    {
      path: '/employee-signature-status',
      component: () =>
        import('@/views/sign-document/employee-signature-status/index.vue'),
      meta: {
        title: '员工签署情况',
        icon: 'contract-sign'
      }
    }
  ]
}
