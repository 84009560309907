import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { filterRoutes, generateMenus } from '@/utils/route';
import SidebarItem from '@/views/layout/components/Sidebar/SidebarItem.vue';
import variables from '@/styles/variables.module.scss';
export default {
  __name: 'SidebarMenu',
  setup(__props) {
    const router = useRouter();
    const routes = computed(() => {
      const fRoutes = filterRoutes(router.getRoutes());
      return generateMenus(fRoutes);
    });
    const route = useRoute();
    const activeMenu = computed(() => {
      const {
        path
      } = route;
      let result = path;
      router.getRoutes().forEach(item => {
        if (path.indexOf(item.path) > -1) {
          result = item.path;
        }
      });
      return result;
    });
    return (_ctx, _cache) => {
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        collapse: !_ctx.$store.getters.sidebarOpened,
        "default-active": activeMenu.value,
        "unique-opened": true,
        "background-color": _unref(variables).menuBg,
        "text-color": _unref(variables).menuText,
        "active-text-color": _unref(variables).menuActiveText,
        router: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routes.value, item => {
          return _openBlock(), _createBlock(SidebarItem, {
            key: item.path,
            route: item
          }, null, 8, ["route"]);
        }), 128))]),
        _: 1
      }, 8, ["collapse", "default-active", "background-color", "text-color", "active-text-color"]);
    };
  }
};