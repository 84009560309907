import Layout from '@/views/layout'
export default {
  path: '/app-permission-manage',
  component: Layout,
  redirect: '',
  name: 'appPermissionManage',
  meta: {
    title: '应用权限管理',
    icon: 'permission'
  },
  children: [
    {
      path: '',
      name: 'appPermissionManage',
      component: () => import('@/views/app-permission-manage')
    },
    {
      path: '/app-permission-manage/add-permission',
      name: 'addPermission',
      component: () =>
        import('@/views/app-permission-manage/operation/add-permission.vue'),
      meta: {
        title: '新增权限',
        keepAlive: true
      }
    },
    {
      path: '/app-permission-manage/edit-permission/:id',
      name: 'editPermission',
      component: () =>
        import('@/views/app-permission-manage/operation/edit-assignment.vue'),
      meta: {
        title: '编辑授权',
        keepAlive: true
      }
    },
    {
      path: '/app-permission-manage/edit-role/:id',
      name: 'editRole',
      component: () =>
        import('@/views/app-permission-manage/operation/edit-role.vue'),
      meta: {
        title: '编辑角色',
        keepAlive: true
      }
    }
  ]
}
