import Layout from '@/views/layout'
export default {
  path: '/sign-document',
  component: Layout,
  redirect: '/file-template-upload',
  name: 'fileTemplateUpload',
  meta: {
    title: '签字文件推送',
    icon: 'contract-sign'
  },
  children: [
    {
      path: '/file-template-upload',
      name: 'fileTemplateUpload',
      component: () =>
        import('@/views/sign-document/file-template-upload/index.vue'),
      meta: {
        title: '文件模板上传',
        icon: 'contract-sign'
      }
    },
    {
      path: '/file-template-upload/add',
      name: 'fileTemplateUploadAdd',
      component: () =>
        import('@/views/sign-document/file-template-upload/add-upload.vue'),
      meta: {
        title: '新增导入',
        keepAlive: true
      }
    }
  ]
}
