import { getItem, setItem } from '@/utils/storage'
import { TAGS_VIEW } from '@/constant'
import {
  REMOVE_TAGS_VIEW_MUTATION,
  SET_ADD_TAGS_VIEW_LIST_MUTATION,
  REMOVE_ALL_TAGS_VIEW_MUTATION,
  TRIGGER_SIDEBAR_OPENED_MUTATION
} from '@/store/mutation-types'

export default {
  namespaced: true,
  state: () => ({
    tagsViewList: getItem(TAGS_VIEW) || [],
    sidebarOpened: true
  }),
  mutations: {
    [SET_ADD_TAGS_VIEW_LIST_MUTATION](state, tag) {
      const isFind = state.tagsViewList.find((item) => {
        return item.path === tag.path
      })
      if (!isFind) {
        state.tagsViewList.push(tag)
        setItem(TAGS_VIEW, state.tagsViewList)
      }
    },
    [REMOVE_TAGS_VIEW_MUTATION](state, index) {
      state.tagsViewList.splice(index, 1)
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    [REMOVE_ALL_TAGS_VIEW_MUTATION](state) {
      state.tagsViewList = []
    },
    [TRIGGER_SIDEBAR_OPENED_MUTATION](state, bol) {
      state.sidebarOpened = bol
    }
  },
  actions: {
    removeTagByFullPath({ commit, state }, fullPath) {
      const tagsList = state.tagsViewList
      const index = tagsList.findIndex((item) => item.fullPath === fullPath)
      commit(REMOVE_TAGS_VIEW_MUTATION, index)
    }
  }
}
