export function debounce(fn, wait = 400) {
  let timeout = null
  return function () {
    if (timeout !== null) clearTimeout(timeout)
    timeout = setTimeout(fn, wait)
  }
}

export const checkPassword = (password) => {
  if (!password) return false
  let count = 0
  const digital = /[0-9]/
  const capital = /[A-Z]/
  const lowercase = /[a-z]/
  const spec = /[,.<>{}~!@#$%^&*_]/
  if (digital.test(password)) {
    count++
  }
  if (lowercase.test(password) || capital.test(password)) {
    count++
  }
  if (lowercase.test(password) && capital.test(password)) {
    count++
  }
  if (spec.test(password)) {
    count++
  }
  return count >= 3 && password.length >= 8 && password.length <= 16
}
export const checkUsername = (value) => {
  if (!value) return false
  const reg = /^(?![_.])(?!.*[_.-]{2,})[a-zA-Z0-9_.-]{5,18}$/
  if (reg.test(value)) {
    let underlineLength = 0
    let dotLength = 0
    value.split('').forEach((item) => {
      if (item === '_') {
        underlineLength++
      } else if (item === '.') {
        dotLength++
      }
    })
    return !(underlineLength > 1 || dotLength > 1)
  }
  return false
}

export const filterParams = (obj) => {
  const newObj = {}
  for (const key in obj) {
    if (
      (obj[key] === 0 || obj[key] === false || obj[key]) &&
      obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== ''
    ) {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

export const previewFile = (path) => {
  // const url = 'http://view.xdocin.com/xdoc?_xdoc=' + path
  // // const url = 'https://view.officeapps.live.com/op/view.aspx?src=' + path
  const width = 1000
  const height = 800
  const top = (window.screen.availHeight - height) / 2
  const left = (window.screen.availWidth - width) / 2
  window.open(
    '/pdfjs/web/viewer.html?file=' + path,
    '',
    'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left
  )
}

export const downloadFileByATag = (fileName, url) => {
  const a = document.createElement('a')
  a.download = fileName
  a.href = url
  document.body.appendChild(a)
  a.click()
  a.remove()
}
