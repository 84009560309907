import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-main"
};
import { isTags } from '@/utils/tags';
import { watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const getTitle = route => {
      let title = '';
      if (!route.meta) {
        const pathArr = route.path.split('/');
        title = pathArr[pathArr.length - 1];
      } else {
        title = route.meta.title;
      }
      return title;
    };
    const store = useStore();
    const route = useRoute();
    // const keepAliveList = ref([])
    watch(route, to => {
      if (!isTags(to.path)) return;
      const {
        fullPath,
        meta,
        name,
        params,
        path,
        query
      } = to;
      store.commit('app/addTagsViewList', {
        fullPath,
        meta,
        name,
        params,
        path,
        query,
        title: getTitle(to)
      });
    }, {
      immediate: true
    });
    // watch(
    //   store.getters.tagsViewList,
    //   (list) => {
    //     keepAliveList.value = []
    //     list.forEach((item) => {
    //       if (item.meta?.keepAlive) {
    //         keepAliveList.value.push(item.name)
    //       }
    //     })
    //     console.log(keepAliveList.value, 'keepAliveList')
    //   },
    //   {
    //     immediate: true
    //   }
    // )

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};