const getters = {
  token: (state) => state.user.token,
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  hasPermissionList: (state) => {
    return state.permission.permission.length > 0
  },
  permission: (state) => state.permission.permission,
  points: (state) => state.permission.points,
  menuList: (state) => state.permission.menuList,
  userInfo: (state) => state.user.userInfo,
  tagsViewList: (state) => state.app.tagsViewList,
  sidebarOpened: (state) => state.app.sidebarOpened
}

export default getters
