import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ee5e3a7a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "navbar"
};
const _hoisted_2 = {
  class: "right-menu"
};
const _hoisted_3 = {
  class: "username"
};
import { useStore } from 'vuex';
import { ref } from 'vue';
import ChangePassword from '@/views/layout/components/Navbar/change-password.vue';
import { ArrowDown } from '@element-plus/icons-vue';
import { syncWxDepartment } from '@/api/sys';
import { ElMessage } from 'element-plus';
export default {
  __name: 'index',
  setup(__props) {
    const store = useStore();
    const changePasswordVisible = ref(false);
    const handleChangePassword = () => {
      changePasswordVisible.value = true;
    };
    const logout = () => {
      store.dispatch('user/logout');
    };
    const fwUpdate = () => {
      syncWxDepartment();
      ElMessage.success('架构正在更新中，大约需要5-10分钟，请勿重复点击；');
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_dropdown, {
        trigger: "click",
        "max-height": "126px"
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            onClick: handleChangePassword
          }, {
            default: _withCtx(() => [_createTextVNode("修改密码")]),
            _: 1
          }), _createVNode(_component_el_dropdown_item, {
            onClick: logout
          }, {
            default: _withCtx(() => [_createTextVNode("注销")]),
            _: 1
          }), _ctx.$store.getters.userInfo.userName === 'admin' ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
            key: 0,
            onClick: fwUpdate
          }, {
            default: _withCtx(() => [_createTextVNode("架构更新")]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", null, "你好，" + _toDisplayString(_ctx.$store.getters.userInfo.userName), 1)]), _createVNode(_component_el_icon, {
          size: "18",
          color: "#000000"
        }, {
          default: _withCtx(() => [_createVNode(_unref(ArrowDown))]),
          _: 1
        })])]),
        _: 1
      })]), _createVNode(ChangePassword, {
        modelValue: changePasswordVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => changePasswordVisible.value = $event)
      }, null, 8, ["modelValue"])], 64);
    };
  }
};