import axios from 'axios'
export const pendingList = new Map()
// 生成各个请求标识
export const getFetchKey = (config) => {
  console.log(config)
  const { url, data, method } = config
  let token
  if (method === 'get') {
    token = [method, url].join('&')
  } else {
    token = [method, url, JSON.stringify(data)].join('&')
  }
  return token
}
// 添加pending请求
export const addPending = (config) => {
  const fetchKey = getFetchKey(config)
  if (fetchKey) {
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!pendingList.has(fetchKey)) {
          pendingList.set(fetchKey, cancel)
        }
      })
  }
  console.log(pendingList, config, 'addPending')
}
// 移除pending请求
export const removePending = (config) => {
  const fetchKey = getFetchKey(config)
  if (fetchKey) {
    if (pendingList.has(fetchKey)) {
      pendingList.delete(fetchKey)
    }
  }
  console.log(pendingList, config, 'removePending')
}
// 取消请求
export const cancelPending = (config) => {
  const fetchKey = getFetchKey(config)
  if (fetchKey) {
    if (pendingList.has(fetchKey)) {
      const cancel = pendingList.get(fetchKey)
      cancel('cancel')
      pendingList.delete(fetchKey)
    }
  }
}
