import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b9f0ff0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
export default {
  __name: 'index',
  props: {
    title: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'is:closed', 'on:submit'],
  setup(__props, {
    emit: emits
  }) {
    const closed = () => {
      emits('update:modelValue', false);
      emits('is:closed', true);
    };
    const confirm = () => {
      emits('on:submit', () => {
        closed();
      });
    };
    return (_ctx, _cache) => {
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        "model-value": __props.modelValue,
        "append-to-body": true,
        width: "640px",
        height: "520px",
        draggable: "",
        onClose: closed
      }, {
        header: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1)]),
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: closed
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: confirm,
          loading: __props.confirmLoading
        }, {
          default: _withCtx(() => [_createTextVNode(" 确认 ")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_scrollbar, null, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "content")]),
          _: 3
        })]),
        _: 3
      }, 8, ["model-value"]);
    };
  }
};