import Layout from '@/views/layout'
export default {
  path: '/permission-manage',
  component: Layout,
  redirect: '/role',
  name: 'roleManage',
  meta: {
    title: '权限管理',
    icon: 'account'
  },
  children: [
    {
      path: '/role',
      name: 'permissionManageRole',
      component: () => import('@/views/permission-manage/role/index.vue'),
      meta: {
        title: '角色权限',
        icon: 'account'
      }
    },
    {
      path: '/role/add',
      name: 'permissionManageRoleAdd',
      component: () => import('@/views/permission-manage/role/add.vue'),
      meta: {
        title: '新增角色',
        keepAlive: true
      }
    },
    {
      path: '/role/add/:id',
      name: 'permissionManageRoleEdit',
      component: () => import('@/views/permission-manage/role/add.vue'),
      meta: {
        title: '编辑角色',
        keepAlive: true
      }
    }
  ]
}
