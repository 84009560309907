import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ffccfc0e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tags-view-container"
};
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import router from '@/router';
export default {
  __name: 'index',
  setup(__props) {
    const store = useStore();
    const tagsViewList = store.getters.tagsViewList;
    const route = useRoute();
    const isActive = tag => {
      return tag.path === route.path;
    };
    const onCloseClick = (index, path) => {
      store.commit('app/removeTagsView', index);
      if (store.getters.tagsViewList.length <= 0) {
        router.push('/');
      }
      if (path === route.path && store.getters.tagsViewList.length > 0) {
        router.push(store.getters.tagsViewList[0].path);
      }
    };
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        height: "44px"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tagsViewList), (tag, index) => {
          return _openBlock(), _createBlock(_component_router_link, {
            key: index,
            class: _normalizeClass(["tags-view-item", isActive(tag) ? 'active' : 'inactive']),
            to: {
              path: tag.fullPath
            }
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(tag.title) + " ", 1), _createVNode(_component_svg_icon, {
              className: "icon",
              icon: "close",
              onClick: _withModifiers($event => onCloseClick(index, tag.fullPath), ["prevent", "stop"])
            }, null, 8, ["onClick"])]),
            _: 2
          }, 1032, ["class", "to"]);
        }), 128))])]),
        _: 1
      });
    };
  }
};