import Layout from '@/views/layout'
export default {
  path: '/sign-document',
  component: Layout,
  name: 'pushTaskCreation',
  redirect: '/push-task-creation',
  meta: {
    title: '签字文件推送',
    icon: 'contract-sign'
  },
  children: [
    {
      path: '/push-task-creation',
      component: () =>
        import('@/views/sign-document/push-task-creation/index.vue'),
      meta: {
        title: '推送任务创建',
        icon: 'contract-sign'
      }
    },
    {
      path: '/push-task-creation/add',
      name: 'pushTaskCreationAdd',
      component: () =>
        import('@/views/sign-document/push-task-creation/add/index.vue'),
      meta: {
        title: '新增推送',
        keepAlive: true
      }
    },
    {
      path: '/push-task-creation/edit/:id',
      name: 'pushTaskCreationEdit',
      component: () =>
        import('@/views/sign-document/push-task-creation/edit.vue'),
      meta: {
        title: '修改推送范围',
        keepAlive: true
      }
    }
  ]
}
