import Layout from '@/views/layout'
export default {
  path: '/permission-manage',
  component: Layout,
  redirect: '/user',
  name: 'userManage',
  meta: {
    title: '权限管理',
    icon: 'account'
  },
  children: [
    {
      path: '/user',
      name: 'permissionManageUser',
      component: () => import('@/views/permission-manage/user/index.vue'),
      meta: {
        title: '用户权限',
        icon: 'account'
      }
    },
    {
      path: '/user/add',
      name: 'permissionManageUserAdd',
      component: () => import('@/views/permission-manage/user/add.vue'),
      meta: {
        title: '新增用户',
        keepAlive: true
      }
    },
    {
      path: '/user/add/:id',
      name: 'permissionManageUserEdit',
      component: () => import('@/views/permission-manage/user/add.vue'),
      meta: {
        title: '编辑用户',
        keepAlive: true
      }
    }
  ]
}
