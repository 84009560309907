import { createStore, createLogger } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import permission from './modules/permission'
import getters from '@/store/getters'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  getters,
  modules: {
    user,
    app,
    permission
  },
  plugins: debug ? [createLogger()] : []
})
