import { privateRoutes, publicRoutes } from '@/router'
import {
  SET_PERMISSION_MUTATION,
  SET_POINTS,
  SET_ROUTES_MUTATION
} from '@/store/mutation-types'
import { getMenuList } from '@/api/menu'

export default {
  namespaced: true,
  state: () => ({
    routes: [],
    permission: [],
    points: []
  }),
  mutations: {
    [SET_ROUTES_MUTATION](state, newRoutes) {
      state.routes = [...publicRoutes, ...newRoutes]
    },
    [SET_PERMISSION_MUTATION](state, list) {
      state.permission = list
    },
    [SET_POINTS](state, list) {
      state.points = list
    }
  },
  actions: {
    async getPermissionList({ commit }) {
      const { data } = await getMenuList()
      const menuList = []
      const pointList = []
      for (const item of data) {
        if (!item.children || item.children.length <= 0) {
          menuList.push(item.url)
        } else {
          for (const obj of item.children) {
            menuList.push(obj.url)
            if (obj.children && obj.children.length > 0) {
              obj.children.forEach((cObj) => {
                pointList.push(cObj.menuId)
              })
            }
          }
        }
      }
      commit(
        SET_PERMISSION_MUTATION,
        menuList.length > 0 ? menuList : publicRoutes
      )
      commit(SET_POINTS, pointList)
      return menuList
    },
    filterRoutes({ commit }, menus) {
      const routes = []
      menus.forEach((key) => {
        routes.push(...privateRoutes.filter((item) => item.name === key))
      })
      routes.push({
        path: '/:catchAll(.*)',
        redirect: '/404'
      })
      commit(SET_ROUTES_MUTATION, routes)
      return routes
    }
  }
}
