import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import JsonBig from 'json-bigint'
// import { isCheckTimeout } from '@/utils/auth'
import { addPending, removePending, pendingList } from './cancel-request'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 0,
  transformResponse: [
    function (data) {
      try {
        return JsonBig.parse(data)
      } catch (err) {
        return data
      }
    }
  ]
})

service.interceptors.request.use(
  (config) => {
    addPending(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const { data, config } = response
    removePending(config)
    if (
      config.responseType === 'arraybuffer' ||
      config.responseType === 'blob'
    ) {
      return data
    } else {
      const errMsgList = ['您已在别处登录，请您修改密码或重新登录']
      const { code, msg } = data
      if (code === 0 || code === 200) {
        return data
      } else if (code === 40029) {
        ElMessage.error('登录信息过期，请重新登录')
        store.dispatch('user/logout')
      } else if (errMsgList.indexOf(msg) > -1) {
        ElMessage.error(msg || '系统错误')
        store.dispatch('user/logout')
      } else {
        ElMessage.error(msg || '系统错误')
        return Promise.reject(new Error(msg || '系统错误'))
      }
    }
  },
  (error) => {
    console.log(error, 'error')
    removePending(error.config)
    if (error.code === 'ERR_NETWORK') {
      const pendingArr = pendingList.values()
      let current = pendingArr.next()
      while (!current.done) {
        current.value('cancel')
        current = pendingArr.next()
      }
      pendingList.clear()
      ElMessage.error('登录过期或失效，请重新登录。')
      store.dispatch('user/logout')
    }
    // ElMessage.error(error.message)
    return Promise.reject(error)
  }
)
export default service
