import { login, loginProfile } from '@/api/sys'
// import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import {
  REMOVE_ALL_TAGS_VIEW_MUTATION,
  SET_TOKEN_MUTATION,
  SET_USERINFO_MUTATION
} from '@/store/mutation-types'
import router, { resetRouter } from '@/router'
import { setTimeStamp } from '@/utils/auth'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {}
  }),
  mutations: {
    [SET_TOKEN_MUTATION](state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    [SET_USERINFO_MUTATION](state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    login({ commit }, userInfo) {
      const { username, password, validateCode } = userInfo
      return new Promise((resolve, reject) => {
        login({
          username,
          password: password,
          validateCode,
          rememberMe: false
        })
          .then((res) => {
            commit(SET_TOKEN_MUTATION, 123)
            router.push('/')
            setTimeStamp()
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getUserInfo({ commit }) {
      const { data } = await loginProfile()
      commit(SET_USERINFO_MUTATION, data)
    },
    logout({ commit }) {
      resetRouter()
      commit(SET_TOKEN_MUTATION, '')
      commit(SET_USERINFO_MUTATION, {})
      commit('app/' + [REMOVE_ALL_TAGS_VIEW_MUTATION], '', { root: true })
      removeAllItem()
      router.push('/login')
    }
  }
}
