import Layout from '@/views/layout'
export default {
  path: '/account-list',
  component: Layout,
  redirect: '',
  name: 'account-list',
  meta: {
    title: '账号列表',
    icon: 'account'
  },
  children: [
    {
      path: '',
      name: 'accountListIndex',
      component: () => import('@/views/account-list')
    }
  ]
}
