import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view, null, {
          default: _withCtx(({
            Component
          }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.name
          })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.name
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};