import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
export default {
  __name: 'SidebarItem',
  props: {
    route: {
      type: Object,
      required: true
    },
    isChildren: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_sidebar_item = _resolveComponent("sidebar-item", true);
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      return __props.route.children.length > 0 ? (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 0,
        index: __props.route.path
      }, {
        title: _withCtx(() => [_createVNode(_component_svg_icon, {
          icon: __props.route.meta.icon,
          class: "menu-svg-icon"
        }, null, 8, ["icon"]), _createElementVNode("span", null, _toDisplayString(__props.route.meta.title), 1)]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.route.children, item => {
          return _openBlock(), _createBlock(_component_sidebar_item, {
            key: item.path,
            "is-children": "",
            route: item
          }, null, 8, ["route"]);
        }), 128))]),
        _: 1
      }, 8, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 1,
        index: __props.route.path
      }, {
        title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(__props.route.meta.title), 1)]),
        default: _withCtx(() => [!__props.isChildren ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 0,
          icon: __props.route.meta.icon,
          class: "menu-svg-icon"
        }, null, 8, ["icon"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["index"]));
    };
  }
};