export const SET_TOKEN_MUTATION = 'setToken'
export const SET_USERINFO_MUTATION = 'setUserInfo'

export const SET_ADD_TAGS_VIEW_LIST_MUTATION = 'addTagsViewList'

export const REMOVE_TAGS_VIEW_MUTATION = 'removeTagsView'
export const REMOVE_ALL_TAGS_VIEW_MUTATION = 'removeAllTagsView'

export const SET_ROUTES_MUTATION = 'setRoutes'

export const SET_PERMISSION_MUTATION = 'setPermission'

export const SET_POINTS = 'setPoints'

export const SET_MENU_LIST_MUTATION = 'setMenuList'

export const TRIGGER_SIDEBAR_OPENED_MUTATION = 'triggerSidebarOpened'
